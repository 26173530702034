<template lang="pug">
  v-flex
    .notices(
      v-if="settings",
      v-for="(value, key) in settings",
      :key="key",
      :class="{ loading }")
      .notices_section
        .notices_head
          .notices_info
          .notices_checkboxes
            .checkbox_item
              .checkbox_title {{ $t('formFields.email') }}
              v-checkbox.main_checkbox(
                v-model="allEmail[key]",
                :value="isAllEmailSelected(key)",
                @change="val => handleGroupChange(val, key, 'is_email_enabled')")
            //- .checkbox_item
            //-   .checkbox_title {{ $t('common.system') }}
            //-   v-checkbox.main_checkbox(
            //-     v-model="allSystem[key]",
            //-     :value="isAllSystemSelected(key)",
            //-     @change="val => handleGroupChange(val, key, 'is_internal_enabled')")
      .notices_section
        h2
          Logo(:serviceName="key")
        .notices_item(v-for="setting in value")
          .notices_info
            .notices_title {{ $t(`notifications.${setting.notify_name}.title`) }}
            .notices_description {{ $t(`notifications.${setting.notify_name}.description`) }}
          .notices_checkboxes
            .checkbox_item
              v-checkbox.main_checkbox(v-model="settings[key][setting.id].is_email_enabled", @change="val => handleSettingChange(val, setting.id, 'is_email_enabled')")
            //- .checkbox_item
            //-   v-checkbox.main_checkbox(v-model="settings[key][setting.id].is_internal_enabled", @change="val => handleSettingChange(val, setting.id, 'is_internal_enabled')")
</template>

<script>
import store from '@/store/store';
import Logo from '@/components/Logo';
import CONSTANTS from '@/constants';

export default {
  components: { Logo },
  data() {
    return {
      loading: false,
      services: CONSTANTS.SERVICES_MENU,
      settingsArr: [],
      settings: null,
      allEmail: {},
      allSystem: {}
    };
  },
  methods: {
    isAllEmailSelected(serviceName) {
      this.allEmail[serviceName] = Object.values(this.settings[serviceName]).every(item => item.is_email_enabled);
    },
    isAllSystemSelected(serviceName) {
      this.allSystem[serviceName] = Object.values(this.settings[serviceName]).every(item => item.is_internal_enabled);
    },
    handleGroupChange(val, serviceName, propName) {
      const serviceId = this.services.find(s => s.short === serviceName).id;

      if (this.loading) return;

      this.loading = true;

      const changedArr = [];

      this.settingsArr.forEach(setting => {
        if (setting.platform_id === +serviceId) {
          setting[propName] = val;
          changedArr.push(setting);
        }
      });

      this.$store.dispatch('user/updateSettings', changedArr)
        .catch(err => console.log(err))
        .finally(() => { this.loading = false; });
    },
    handleSettingChange(val, settingId, propName) {
      if (this.loading) return;

      this.loading = true;

      const changed = this.settingsArr.find(i => i.id === settingId);

      changed[propName] = val;

      this.$store.dispatch('user/updateSettings', [changed])
        .catch(err => console.log(err))
        .finally(() => { this.loading = false; });
    }
  },
  beforeRouteEnter: async (to, from, next) => {
    const settings = await store.dispatch('user/getNotificationsSettings');
    const res = {};
    const messenger = CONSTANTS.SERVICES_MENU.find(i => i.id === 6);
    const filtered = settings.filter(item => item.platform_id === messenger.id && item.notify_name.startsWith('someone'));

    if (filtered.length) {
      filtered.forEach(item => {
        if (res[messenger.short]) {
          res[messenger.short][item.id] = item;
        } else {
          res[messenger.short] = {};
          res[messenger.short][item.id] = item;
        }
      });
    }

    next(vm => {
      vm.settings = res;
      vm.settingsArr = filtered;

      Object.keys(res).forEach(key => {
        vm.isAllEmailSelected(key);
        vm.isAllSystemSelected(key);
      });
    });
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .notices {
    text-align: left;
    padding-top: 60px;
    opacity: 1;
    transition: all .3s $easing;

    &.loading { opacity: .5; }

    h2 {
      padding: 20px 0 10px;
      font-size: 30px;
      line-height: 1;
      border-bottom: 1px solid $light-theme-line;

      > svg {
        width: 30px;
        margin-right: 10px;
        margin-bottom: -4px;
      }
    }

    &_section {
      max-width: 1080px;
      display: flex;
      flex-direction: column;
      margin: 0 auto 60px;
      padding: 0 20px;

      &:last-child { margin-bottom: 0; }
    }

    &_head,
    &_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $light-theme-line;
    }
    &_head { border-bottom: 0; }

    &_item {
      padding: 12px 0;

      &:last-child { border-bottom: 0; }
    }

    &_info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 12px;
    }

    &_checkboxes {
      display: flex;
      align-items: center;
      justify-content: center;

      .checkbox {
        &_title {
          font-size: 14px;
          font-weight: bold;
        }

        &_item {
          width: 66px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-left: 22px;

          @media screen and (max-width: 420px) { margin-left: 2px; }
        }
      }
    }

    &_description {
      font-size: 14px;
      font-weight: 500;
      color: $light-theme-secondary-text;
    }

    &_title {
      font-size: 16px;
      color: $sub-black;
      font-weight: 600;
    }
  }
</style>
